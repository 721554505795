<template>
    <v-overlay :value="isLoading" z-index="999">
        <v-progress-circular
                indeterminate
                size="64"

        ></v-progress-circular>
    </v-overlay>
</template>

<script>
    import {EventBus} from "@/helper/EventBus";

    export default {
        name: "LoaderComponent",
        data: () => (
            {
                isLoading: false
            }
        ),
        created() {
            EventBus.$on("toggle", (status) =>{
                this.isLoading = status
            })
        }
    }
</script>
