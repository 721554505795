<template>
    <v-app>
        <v-main>
            <v-container fluid fill-height class="text-center">
                <v-row align="center" justify="center">
                    <v-col >
                        <v-img
                                :src="require('@/assets/dribbble_1.gif')"
                                alt="404 Not Found"
                                contain
                                height="400"
                        />
                        <h1 class="display-1">404</h1>
                        <h3>Page not found</h3>
                        <p>The page you requested could not be found.</p>
                        <v-btn to="/" outlined color="error">Go Back Home</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: "notfoundComponent"
    }
</script>

<style scoped>

</style>