<template>
    <v-snackbar :color="color"  tile :timeout="timeout" v-model="showSnackbar" bottom>
        <v-icon :color="iconColor">{{ icon }}</v-icon>
        {{ message }}
    </v-snackbar>
</template>

<script>
    import {EventBus} from "@/helper/EventBus"

    export default {
        name: "actionStatus",
        data() {
            return {
                showSnackbar: false,
                message: '',
                timeout: 3000,
                color: '',
                icon: '',
                iconColor: '',
            };
        },
        created() {
            EventBus.$on("showMsg",  (data)=> {
                this.message = data.message;
                this.color = data.color;
                this.showSnackbar = true;

                // Set icon based on color
                switch (data.color) {
                    case 'error':
                        this.icon = 'mdi-alert-circle';
                        this.iconColor = 'white';
                        break;
                    case 'warning':
                        this.icon = 'mdi-information';
                        this.iconColor = 'white';
                        break;
                    case 'success':
                        this.icon = 'mdi-check-circle';
                        this.iconColor = 'white';
                        break;
                    default:
                        this.icon = '';
                        this.iconColor = '';
                }
            });


        },
    }
</script>

<style scoped>

</style>